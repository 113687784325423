import React, { useState, useEffect } from "react";
import HtmlEditor from "./HtmlEditor";

function ContentForm({content, onEdit, onDelete, onSubmit, onCancel}){

    const [formBody, setFormBody] = useState({
        Title:'',
        Content:''
    })

    useEffect(() => {
        setFormBody(content)
      },[content]);

    function onChange(e){
        var {name, value, dataset} = e.target
        var last = formBody[name];

        if(dataset && dataset.max){
            if(value.length>=dataset.max){
                value = last
                e.target.value = last
            }
        }
        setFormBody({ ...formBody,  [name]: value })   
    }

    function editorCalback(text){
        onChange({target: {name:'Content', value: text}})
    }

    function formSubmit(e){
        e.preventDefault();
        if(onSubmit)
        {
            onSubmit(formBody);
        }
    }

    function formDelete(id){
        //onDelete(formBody.PostId);
    }

    function formCancel(){
        onCancel();
    } 
    return (
        <form className="pure-form pure-form-aligned" onSubmit={formSubmit}>

            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="Title">Title</label>
                <input data-max="120" type="text" className="pure-input-3-4" placeholder="Page Title..." name="Title"
                    defaultValue={formBody.Title} 
                    onChange={onChange}/>
            </div>

            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="Content">Content</label>
                <div className="pure-u-3-4">
                    <HtmlEditor contents={formBody.Content} editCallback={editorCalback} />
                </div>
            </div>  
            {/* <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="Content">Content</label>
                <textarea className="pure-input-3-4" placeholder="Page Content..." name="Content"
                    defaultValue={formBody.Content} 
                    onChange={onChange}/>
            </div> */}
            <div className="pure-controls">
                <input className="pure-button pure-button-primary" type="submit" value={content.PostContentId===0 ? "Submit" : "Update"}/>
                {content.PostContentId!==0 &&
                <button className="pure-button img-btn" onClick={formDelete} title="Delete">
                    <i className="fa fa-delete"></i>
                </button>
                }
                <button className="pure-button img-btn" onClick={formCancel} title="Cancel">
                    <i className="fa fa-cancel"></i>
                </button>
            </div>

        </form>
  
    )
}

export default ContentForm