import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PostController as API } from "../../service/PostController";
import { SiteUtil } from "../../util/SiteUtil";

function Articles() {
  const pathname = window.location.pathname;

  const [page, setPage] = useState({
    SiteName:"",
    Title:"", 
    Description:"", 
    Summary:"", 
    BannerImg:"",
    Content:[],
    SideMenu:[], 
    MainMenu:[]
    });
  
  useEffect(() => {
    let pageName = pathname;

    async function fetchData() {
      API.getPost(pageName).then((data) => {
        data.SideMenu.sort((a, b) => {
          if (a.Created > b.Created) { return 1; }
          if (b.Created > a.Created) { return -1; }
          return 0;
        });
        setPage(data)
      })
    }
    fetchData();
  }, [pathname]); // Or [] if effect doesn't need props or state

    return (
      <>
      <div className="content-wrapper top">
        <div className="content short">
          <div className={"pure-u-1 panel"}>
            {page.Description && 
              <h2>{page.Description}</h2>
            }
            <div dangerouslySetInnerHTML={{__html: page.Summary}}/>
          </div>
        </div>

        {page.SideMenu && page.SideMenu.length > 0 &&
        <div className="content">
          <div className="panel pure-g">
          {
            page.SideMenu.map((d, i) =>
            
              <div className="l-box pure-u-1 pure-u-md-1-2 pure-u-lg-1-3" key={i}>
                <Link className="cover-link" to={d.PostName}>                
                <div className="image" style={{backgroundImage: 'url('+SiteUtil.localiseImgUrl(d.BannerImg)+')'}}></div>
                <div className="title">
                {d.Description &&
                  <h3>{d.Description}</h3>
                }
                </div>
              </Link>
              </div>

            )
          }
          </div>
        </div>
        } 
      </div>
    </>  
    );
  }
  
  export default Articles;