import React, { useState, useEffect } from "react";
import HtmlEditor from "./HtmlEditor";
import { SiteUtil } from "../util/SiteUtil";

function EditForm({ onSubmit, onDelete, form, hasChildren, parentUrl }){

    var isHome = form.PostHomeId === null
    var isPage = form.PostHomeId !== form.PostParentId
    var canSetTemplate = form.PostParentId === null || form.PostHomeId === form.PostParentId;

    const [formBody, setFormBody] = useState({
        PostId:null, 
        PostName:'', 
        Title:'', 
        Description:'',
        Author:'',
        Summary:'',
        Template:'',
        BannerImg:'',
        Dubious:false,
        Surpressed:false,
        PostParentId:null,
        PostHomeId:null,
        Created:null,
        Modified:null
    })

    useEffect(() => {
        setFormBody(form)
      },[form]);

    function onChange(e){
        var {name, value, dataset} = e.target
        var last = formBody[name];

        if(dataset && dataset.max){
            if(value.length>=dataset.max){
                value = last
                e.target.value = last
            }
        }
        setFormBody({ ...formBody,  [name]: value })   
    }
    function summaryCalback(text){
        onChange({target: {name:'Summary', value: text}})
    }
    function onCheck(e){
        const {name, checked} = e.target
        setFormBody({ ...formBody,  [name]: checked })
    }
    function onSelect (e) {
        const {name, value} = e.target
        setFormBody({ ...formBody,  [name]: value })
    }
    function formSubmit(e){
        e.preventDefault();
        onSubmit(formBody);
    }

    function formDelete(e){
        e.preventDefault();
        onDelete(formBody.PostId);
    }

    return (
        <form className="pure-form pure-form-aligned" onSubmit={formSubmit}>            

            <div className="pure-control-group">
                <label className="pure-input-1-4">Created</label>
                {formBody.Created}
            </div>
            <div className="pure-control-group">
                <label className="pure-input-1-4">Modified</label>
                {formBody.Modified}
            </div>

            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="Title">Title</label>
                <input data-max="120" type="text" className="pure-input-3-4" placeholder="Page Title..." name="Title"
                    defaultValue={formBody.Title} 
                    onChange={onChange}/>
            </div>

            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="Description">Description</label>
                <input data-max="200" type ="text" className="pure-input-3-4" placeholder="Page Description..." name="Description"
                    defaultValue={formBody.Description} 
                    onChange={onChange}/>
            </div>

            <div className="pure-control-group">
                {isHome &&
                    <p>This is the web site home page. Setting the banner image will be the banner for the whole site. Use [SITE] for local folder</p>
                }
                {isPage &&
                    <p>For a sub page, the banner image will be used as a thumbnail for the page in the parent listing. Use [SITE] for local folder</p>
                }
                <label className="pure-input-1-4" htmlFor="BannerImg">Banner Image</label>
                <input data-max="200" type="text" className="pure-input-3-4" placeholder="Banner Image..." name="BannerImg"
                    disabled ={!(isHome || isPage)}
                    defaultValue={formBody.BannerImg} 
                    onChange={onChange}/>
            </div>
            {(isHome || isPage) &&
            <div className="pure-control-group">
                <label className="pure-input-1-4" >Preview</label>
                <div className="pure-u-3-4">
                    {formBody.BannerImg &&
                        <img src={SiteUtil.localiseImgUrl(formBody.BannerImg)} alt="Banner preview"/>  
                    }
                </div>
            </div>  
            }

            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="Summary">Summary</label>
                <div className="pure-u-3-4">
                    <HtmlEditor contents={formBody.Summary} editCallback={summaryCalback} />
                </div>                
            </div>  

            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="PostName">Page Name</label>
                <span>{parentUrl}</span>
                <input data-max="80" type ="text" className="pure-input-2-4" placeholder="Page Name..." name="PostName"
                    defaultValue={formBody.PostName} 
                    onChange={onChange}/>
                <span className="pure-input-2-4"> Alpha characters only, no spaces</span>    
            </div>

            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="Template">Template</label>
                <select value={formBody.Template} onChange={onSelect} name="Template" disabled={!canSetTemplate}>
                    <option value="Page">Page</option>
                    <option value="Blog">Blog</option>
                    <option value="Article">Article</option>
                    {!canSetTemplate &&
                        <>
                        <option value="ArticlePage">ArticlePage</option>
                        <option value="BlogPage">BlogPage</option>
                        </>
                    }
                </select>
            </div>

            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="Author">Author</label>
                <input data-max="50" type ="text" className="pure-input-2-4" placeholder="Author..." name="Author"
                    defaultValue={formBody.Author} 
                    onChange={onChange}/>
            </div>

            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="Dubious">Dubious</label>
                <input type ="checkbox" name="Dubious"
                    checked={formBody.Dubious} 
                    onChange={onCheck}/>
            </div>

            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="Surpressed">Surpressed</label>
                <input type ="checkbox" name="Surpressed"
                    checked={formBody.Surpressed} 
                    onChange={onCheck}/>
            </div>

            <div className="pure-controls">
                <input className="pure-button pure-button-primary" type="submit" value="Update"/>
                {!hasChildren &&
                <button className="pure-button img-btn" onClick={formDelete} title="Delete">
                    <i className="fa fa-delete"></i>
                </button>
                }
            </div>

        </form>          
    )
}

export default EditForm