import React, { useState } from "react";
import JoditEditor from 'jodit-react';

function HtmlEditor({contents, editCallback}){

	const [config, setConfig] = useState({
		readonly: false,
		toolbar: true
	});

    function handleBlurAreaChange(textAreaValue, event) {
		//console.log('handleBlurAreaChange', textAreaValue, event);
	}

	function handleWYSIWYGChange(newTextAreaValue){
        if(editCallback && newTextAreaValue)
        {
            editCallback(newTextAreaValue);
        }
	}   

    return (
        <JoditEditor
            config={config}
            onChange={handleWYSIWYGChange}
            onBlur={handleBlurAreaChange}
            value={contents || ''}
        />
  
    )
}

export default HtmlEditor