const authReducer = (state = null, action) =>{
    switch(action.type){
      case 'SET_TOKEN':
        return state = action.payLoad
      case 'GET_TOKEN':
        return action.payLoad
      default:
        return state;  
    }
  }

  export default authReducer;