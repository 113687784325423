import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { PostController as API } from "../../service/PostController";

function Blog() {
  const pathname = window.location.pathname;

  const [page, setPage] = useState({
    SiteName:"",
    Title:"", 
    Description:"", 
    Summary:"", 
    Content:[],
    SideMenu:[], 
    MainMenu:[]
    });
  
  useEffect(() => {
    let pageName = pathname;

      async function fetchData() {
        API.getPost(pageName).then((data) => {
          setPage(data)
        })
    }
    fetchData();
  }, [pathname]); // Or [] if effect doesn't need props or state


    return (
      <>
      <div className="content-wrapper top">
        <div className="submenu-container">
          <div className="pageNav">
              {page.Content &&                
                  page.SideMenu.map((d, i) => 
                      <Link key={i} className="pure-menu-link" to={d.PostName}>{d.Title}</Link>
                  )
              }  
          </div>
        </div>
        <div className="content">
          <div className={"pure-u-1 panel"}>
            {page.Description && 
              <h2>{page.Description}</h2>
            }
            <div dangerouslySetInnerHTML={{__html: page.Summary}}/>
          </div>
        </div>

        {page.Content && page.Content.length > 0 &&
        <>
          <div className="divider div-transparent"></div>
          {
            page.Content.map((d, i) => 
            <div key={i}>
              <div className="content" key={i}>
                
                <div className="pure-u-1 panel">
                  {d.Title &&
                    <h3 className="pure-u-1 pure-u-md-1-2">{d.Title}</h3>
                  }  
                  <p className="pure-u-1 pure-u-md-1-2 right">{d.Created}</p>
                  <div dangerouslySetInnerHTML={{__html: d.Content}}/>
                </div>
              </div>
              <div className="divider div-transparent"></div>
            </div>
            )
          }
        </>
        } 
      </div>
  </> 
    );
  }
  
  
  export default Blog;