import React, {useState} from "react";
import Content from "./Content";

function Contents({contents, onSubmit, onDelete}){

    const [mode, setMode] = useState(0)

    function newContent(e){
        e.preventDefault();
        setMode(1);
    }

    function submitContent(content){
        setMode(0);
        if(onSubmit)
        {
            onSubmit(content)
        }
    }
    
    function deleteContent(id){
        if(onDelete){
            onDelete(id)
        }
    }

    function cancel(){
        setMode(0);
    }    
    return (
        <div>
            {mode===0 &&
            <>
                <div className="pure-controls">
                    <input className="pure-button pure-button-primary" type="button" onClick={newContent} 
                    value="Add Content"/>
                </div>

                {contents &&
                    contents.map((content, i) => 
                        <Content content={content} 
                        onSubmit={submitContent} onDelete={deleteContent} editMode={0} key={i}/>
                    )
                }
            </>
            }
            {mode === 1 &&
            <>
                <Content content={{PostContentId:0, Title:'',Content:''}} editMode={1} 
                    onCancel={cancel} onSubmit={submitContent}/>
            </>
            }

        </div>
  
    )
}

export default Contents