import React, { useState, useEffect } from "react";
import {Link, useParams} from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';

import { AdminController as API } from "../service/AdminController";
import Login from "./Login";
import CreateForm from "./CreateForm";
import EditForm from "./EditForm";
import Contents from "./Contents";
import './admin.scss';


function Admin(){

    let { id } = useParams();

    const dispatch = useDispatch();
    //const isLoggedIn = useSelector(state => state.isLogged);
    const authToken = useSelector(state => state.token);

    const initialForm = {
        PostId:null, 
        PostName:'', 
        Title:'', 
        Description:'',
        Author:'',
        Summary:'',
        Template:'',
        BannerImg:'',
        Dubious:false,
        Surpressed:false,
        PostParentId:null,
        PostHomeId:null,
        Created:null,
        Modified:null
    }

    const [isLoggedIn, setLoggedIn] = useState(useSelector(state => state.isLogged))    
    const [message, setmessage] = useState(null)
    const [fullUrl, setFullUrl] = useState(null)
    const [parentUrl, setParentUrl] = useState(null)
    const [parentBack, setParent] = useState(null)
    const [homeBack, setHome] = useState(null)
    const [mode, setMode] = useState(0)
    const [formBody, setFormBody] = useState(initialForm)
    const [children, setChildren] = useState([])
    const [content, setContent] = useState([])

    useEffect(() => {

        if(!authToken)
        {
            if(localStorage.getItem('currentUser'))
            {
               let user =JSON.parse(localStorage.getItem('currentUser'));

               try {
                    API.getUser().then((data) => {   
                        if(data){
                            onLoggedIn(user.access_token);
                        }
                    })
                } catch (error) {

                }
            }
        }
        else{
            if(id){
                fetchPostData(id);
            }
            else{
                fetchAdminHome();
            }            
        }
    }, [id]);

    function onLoggedIn(token){
        dispatch({
            type : 'SIGN_IN',
            payLoad: token != null
        });

        dispatch({
            type : 'SET_TOKEN',
            payLoad: token
        });

        setLoggedIn(token != null);

        if(id){
            fetchPostData(id);
        }
        else{
            fetchAdminHome();
        } 
    }

    async function fetchPostData(postId) {
        try {
            API.getPost(postId).then((data) => {
                setFullUrl(data.PostName);
            
                let index = data.PostName.lastIndexOf("/")+1;

                let root = data.PostName.substring(0, index);
                let page = data.PostName.substring(index);

                data.PostName = page || 'root';

                setFormBody({
                    PostId:data.PostId, 
                    PostName:data.PostName, 
                    Title:data.Title, 
                    Description:data.Description,
                    Summary:data.Summary,
                    Template:data.Template,
                    BannerImg:data.BannerImg,
                    Author: data.Author,
                    Dubious:data.Dubious,
                    Surpressed:data.Surpressed,
                    PostHomeId:data.PostHomeId,
                    PostParentId:data.PostParentId,
                    Created:data.Created,
                    Modified:data.Modified
                });

                setParent(data.Parent);
                setHome(data.Home);
                setParentUrl(root);
                setChildren(data.Children);   
                setContent(data.Content);
                setMode(2);
            })
        } catch (error) {
            
        }
    }

    async function fetchAdminHome() {
        try {
            API.getAdmin().then((data) => {   
                setFormBody(initialForm);
                setFullUrl(null);
                setParentUrl(null);
                setParent(null);
                setHome(null);
                setChildren(data.Children);
                setMode(0);
            })

        } catch (error) {

        }
    }

    async function addPost(post) {
        try {
            API.addPost(post).then((data) => {
                if(data.StatusCode!==500){
                    setFullUrl(data.PostName);
                    let index = data.PostName.lastIndexOf("/")+1;
                    let root = data.PostName.substring(0, index);
                    let page = data.PostName.substring(index);
                    data.PostName = page || 'root';
        
                    setFormBody({
                        PostId:data.PostId, 
                        PostName:data.PostName, 
                        Title:data.Title, 
                        Description:data.Description,
                        Summary:data.Summary,
                        Template:data.Template,
                        BannerImg:data.BannerImg,
                        Author: data.Author,
                        Dubious:data.Dubious,
                        Surpressed:data.Surpressed,
                        PostHomeId:data.PostHomeId,
                        PostParentId:data.PostParentId,
                        Created:data.Created,
                        Modified:data.Modified
                    });
        
                    setParentUrl(root);
                    setChildren(data.Children);   
                    setContent(data.Content);
                    setMode(2);
                }
                else{
                    
                }
            })

        } catch (error) {
            
        }
    }

    async function updatePost(post) {
        try {
            API.updatePost(post.PostId, post).then((data) => {

                let index = data.PostName.lastIndexOf("/")+1;
                let page = data.PostName.substring(index);
                data.PostName = page || 'root';
    
                setFormBody({
                    PostId:data.PostId, 
                    PostName:data.PostName, 
                    Title:data.Title, 
                    Description:data.Description,
                    Summary:data.Summary,
                    Template:data.Template,
                    BannerImg:data.BannerImg,
                    Author: data.Author,
                    Dubious:data.Dubious,
                    Surpressed:data.Surpressed,
                    PostHomeId:data.PostHomeId,
                    PostParentId:data.PostParentId,
                    Created:data.Created,
                    Modified:data.Modified
                });
                setMode(2);
            });

        } catch (error) {
            
        }
    }

    async function deletePost(id) {
        try {
            API.deletePost(id).then((data) => {
                setChildren(
                    children.filter(a =>
                      a.PostId !== id
                    )
                  );
                setMode(0);
            });

        } catch (error) {
            
        }
    }

    async function addContent(postContent) {
        try {
            postContent.PostId = formBody.PostId;
            API.addContent(postContent).then((data) => {
                if(!content){
                    setContent([data]);
                }
                else{
                    setContent([...content, data]);
                }
                
            });
        } catch (error) {
            
        }
    }

    async function updateContent(postContent) {
        try {
            API.updateContent(postContent.PostContentId, postContent).then((data) => {
                const index = content.findIndex(cont => cont.PostContentId === data.PostContentId);
                var contents = [...content]; // important to create a copy, otherwise you'll modify state outside of setState call
                contents[index].Title = data.Title;
                contents[index].Content = data.Content;
                setContent([...contents]);
            });
        } catch (error) {
            
        }
    }
    async function deleteContent(id) {
        try {
            API.deleteContent(id).then((data) => {
                setContent(
                    content.filter(a =>
                      a.PostContentId !== id
                    )
                  );
            });
        } catch (error) {
            
        }
    }

    function onSubmitContent(content){
        if(content.PostContentId===0){
            addContent(content);
        }
        else{
            updateContent(content);
        }
    }
    function onDeleteContent(id){
        deleteContent(id);
    }

    function onCreate(form){
        if(!form.PostName){
            alert("Please add Page Name");
            return;
        }
        if (!form.PostName.match(/^[0-9a-zA-Z_-]+$/)){
            alert("Page Name contains illegal characters");
            return;
        }
        if(form.PostName !== '/'){
            if(fullUrl){
                if(fullUrl === '/'){
                    form.PostName = fullUrl+form.PostName;
                }
                else{
                    form.PostName = fullUrl+'/'+form.PostName;
                }
            }
            else{
                form.PostName = '/'+form.PostName;
            }
        }

        addPost(form);
    }

    function onUpdate(form){
        if(!form.PostName && form.PostName !== 'root'){
            alert("Please add Page Name");
            return;
        }
        if (!form.PostName.match(/^[0-9a-zA-Z_-]+$/)){
            alert("Page Name contains illegal characters");
            return;
        }
        if(form.PostName === 'root'){
            form.PostName = parentUrl;
        }
        else{
            form.PostName = parentUrl+form.PostName;
        }

        setMode(0);
        updatePost(form);
    }

    function onDelete(id){
        setMode(0);
        deletePost(id);
    }

    function newPage(e){
        e.preventDefault();
        setMode(1);

        var f = initialForm;
        f.PostParentId = formBody.PostId;
        f.PostHomeId = formBody.PostHomeId || formBody.PostId;
        if(formBody.Template === "Blog"){
            f.Template = "BlogPage";
        }
        else if(formBody.Template === "Article"){
            f.Template = "ArticlePage";
        }
        else{
            f.Template = formBody.Template;
        }
        setFormBody(f)
    }   

    return (
        <div className="container admin">

            {!isLoggedIn &&
                <Login onLoggedIn={onLoggedIn}/>
            }
            {isLoggedIn &&
            <div className="pure-g">
                <div className="pure-u-3-4">
                    <div className="pure-g">
                        <div className="pure-u-1">
                            <p>{message}</p>
                        </div>
                    </div>

                    <div className="pure-g">
                        <div className="pure-u-1">
                            <h3>Site Admin</h3>
                            <p>{parentUrl ? "You are in "+fullUrl : "You are at the web site root"}</p>
                        </div>
                        <div className="pure-u-1">
                            <button className="pure-button pure-button-primary" onClick={newPage}>
                            {parentUrl ? "New " + fullUrl + " Page" : "New Site"}
                            </button>
                        </div>
                    </div>

                    <div className="pure-g">
                        <div className="pure-u-1">
                            {mode===1 &&
                                <CreateForm onSubmit={onCreate} form={formBody} parentUrl={fullUrl}/>
                            }
                            {mode===2 &&
                                <EditForm onSubmit={onUpdate} onDelete={onDelete} 
                                form={formBody}
                                hasChildren={children && children.length} 
                                parentUrl={parentUrl}/>
                            }
                        </div>
                    </div>

                    {mode===2 &&
                    <div className="pure-g">
                        <div className="pure-u-1">
                            <Contents contents={content} onSubmit={onSubmitContent} onDelete={onDeleteContent}/>
                        </div>
                    </div>
                    }
                </div>
                <div className="pure-u-1-4">
                    <div className="pure-g">
                        <Link to={"/Admin"}>Admin Home</Link>
                        {homeBack !== null && 
                            <div className="pure-u-1"><Link to={"/Admin/"+homeBack.PostId}>{homeBack.Title}</Link></div>
                        }
                        {parentBack !== null && 
                            <div className="pure-u-1"><Link to={"/Admin/"+parentBack.PostId}>{parentBack.Title}</Link></div>
                        }
                        <ul>
                        {children &&                
                            children.map((d, i) => 
                                <li key={i}><Link to={"/Admin/"+d.PostId}>{mode===0 
                                    ? d.PostName +" - "+ d.Title
                                : d.Title || d.PostName}</Link></li>
                            )
                        }
                        </ul>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default Admin