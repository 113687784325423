import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PostController as API } from "../../service/PostController";
import { SiteUtil } from "../../util/SiteUtil";

function Blogs() {
  const pathname = window.location.pathname;

  const [page, setPage] = useState({
    SiteName:"",
    Title:"", 
    Description:"", 
    Summary:"", 
    Content:[],
    SideMenu:[], 
    MainMenu:[]
    });
  
  useEffect(() => {
    let pageName = pathname;

    async function fetchData() {
      API.getPost(pageName).then((data) => {
        
        data.SideMenu.sort((a, b) => {
          if (a.Created > b.Created) { return 1; }
          if (b.Created > a.Created) { return -1; }
          return 0;
        });
        setPage(data)
      })
    }
    fetchData();
  }, [pathname]); // Or [] if effect doesn't need props or state


  return (
      <>
      <div className="splash-container bg1">
        <div className="splash">
          <div className="pure-u-1 panel">
            {page.Description && 
              <h3>{page.Description}</h3>
            }
            <div dangerouslySetInnerHTML={{__html: page.Summary}}/>
          </div>
        </div>
      </div>

      {page.SideMenu && page.SideMenu.length > 0 &&   
        <>
          <div className="divider div-transparent"></div>
          <div className="content-wrapper">     
            {
              page.SideMenu.map((d, i) => 
              <div key={i}>
                <div className="content center" key={i}>
                  <div className="pure-u-1 panel">
                  <Link className="cover-link" to={d.PostName}>                
                    <div className="image" style={{backgroundImage: 'url('+SiteUtil.localiseImgUrl(d.BannerImg)+')'}}></div>
                    <div className="title">
                    {d.Description &&
                      <h3>{d.Description}</h3>
                    }
                    </div>
                  </Link>
                  </div>
                </div>
                <div className="divider div-transparent"></div>
              </div>
              )
            }
          </div>  
        </>            
      } 
    </>  
    );
  }
  
  export default Blogs;