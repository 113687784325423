import React, { useState, useEffect } from "react";
import ContentHtml from "./ContentHtml";
import ContentForm from "./ContentForm";

function Content({content, editMode, onSubmit, onDelete, onCancel}){

    const [mode, setMode] = useState(editMode)
    const [formBody, setFormBody] = useState({
        Title:'',
        Content:''
    })

    useEffect(() => {
        setFormBody(content)
      },[content]);

    function formSubmit(formContent){
        setMode(0);
        if(onSubmit)
        {
            onSubmit(formContent);
        }
    }
    function formEdit(){
        setMode(1);
    }    
    function formDelete(id){
        onDelete(formBody.PostContentId);
    }

    function formCancel(){
        setMode(0);
        if(onCancel){
            onCancel();
        }
    } 
    
    return (
        <div>
            {mode === 0 &&
                <ContentHtml content={content} onEdit={formEdit} onDelete={formDelete} />
            }
            {mode === 1 &&
                <ContentForm content={content} 
                    onSubmit={formSubmit}
                    onCancel={formCancel} />
            }
       
        </div>
  
    )
}

export default Content