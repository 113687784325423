import React, { useState } from "react";
import { AdminController as API } from "../service/AdminController";

function Login({onLoggedIn}){

    const [loginMessage, setLoginMessage] = useState();
    const [formBody, setFormBody] = useState({
        User:'', 
        Password:''
    })
    
    async function login(username, password) {
        try {
            API.login(username, password, loginError).then((data) => {

                localStorage.setItem('currentUser', JSON.stringify(data));
                onLoggedIn(data.access_token);
            });

        } catch (error) {
            console.log("!")
        }
    }

    function onChange(e){

        var {name, value} = e.target
        setFormBody({ ...formBody,  [name]: value })
    }
    
    function formSubmit(e){
        e.preventDefault();
        login(formBody.User, formBody.Password);
    }

    function loginError(e){
        setLoginMessage("Not recognised");
    }
    return (
        <form className="pure-form pure-form-aligned" onSubmit={formSubmit}>
            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="User">User</label>
                <input type="text" className="pure-input-3-4" placeholder="User Name..." name="User"
                    defaultValue={formBody.User} 
                    onChange={onChange}/>
            </div>

            <div className="pure-control-group">
                <label className="pure-input-1-4" htmlFor="Password">Password</label>
                <input type="text" className="pure-input-3-4" placeholder="Password..." name="Password"
                    defaultValue={formBody.Password} 
                    onChange={onChange}/>
            </div>

            <div className="pure-control-group">
                <label className="pure-input-1-4"></label>
                <span className="pure-input-3-4 text-warn">{loginMessage}</span>
            </div>

            <div className="pure-controls">
                <input className="pure-button pure-button-primary" type="submit" value="Login"/>
            </div>
        </form>  
  
    )
}

export default Login