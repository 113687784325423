import React, { useState, useEffect } from "react";
import { PostController as API } from "../../service/PostController";

function Home(){

  const pathname = window.location.pathname;

  const [page, setPage] = useState({
    SiteName:"",
    Title:"", 
    Description:"", 
    Summary:"", 
    Content:[], 
    MainMenu:[]
    });

  useEffect(() => {
    let pageName = pathname;

    async function fetchData() {
      API.getPost(pageName).then((data) => {
        setPage(data)
      })
    }
    fetchData();
  },[pathname]); // Or [] if effect doesn't need props or state

  return (
      <>
        <div className="splash-container bg0">
          <div className="splash">
            <div className="pure-u-1 panel">
              <div dangerouslySetInnerHTML={{__html: page.Summary}}/>
            </div>
          </div>
        </div>
        
        {page.Content && page.Content.length > 0 &&
          <>
            <div className="divider div-transparent"></div>
            <div className="content-wrapper">                         
              {
                page.Content.reverse().map((d, i) => 
                <div key={i}>
                  <div className="content center">
                    <div className="pure-u-1 panel">
                      <div dangerouslySetInnerHTML={{__html: d.Content}}/>
                    </div>
                  </div>
                  <div className="divider div-transparent"></div>
                </div>
                )               
              }
            </div>
          </>
        }
    </>       
  )
}

export default Home