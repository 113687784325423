import { APILayer as API } from "./APILayer"

export const AdminController = {
    login: async function(username, password, onError){
        return API.add('/token', 
        "username=" + username + "&password=" + password + "&grant_type=password",
        onError);
    },
    getUser: async function () {
        return API.getbyName('/tony');
    },
    getAdmin: async function () {
        return API.get('/admin/post');
    },
    getPost: async function (postId) {
        return API.getbyId('/admin/post', postId);
    },
    addPost: async function (payload) {
        return API.add('/admin/post', payload);
    },
    updatePost: async function (Id, payload) {
        return API.update('/admin/post', Id, payload);
    },
    deletePost: async function(Id){
        return API.delete('/admin/post', Id);
    },
    addContent: async function (payload) {
        return API.add('/admin/content', payload);
    },
    updateContent: async function (Id, payload) {
        return API.update('/admin/content', Id, payload);
    },
    deleteContent: async function(Id){
        return API.delete('/admin/content', Id);
    }
}