
function Page404() {

    return (
        <div className="splash-container bg2">
          <div className="splash">
            <div className="pure-u-1 panel">
              <h2>404</h2>
              <p>page not found</p>
            </div>
          </div>
        </div>
    );
  }
  
  
  export default Page404;