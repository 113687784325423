import {Link} from 'react-router-dom'
import { SiteUtil } from '../../util/SiteUtil';
import { useSelector } from 'react-redux';

function PageHeader({links, title, banner}){
    var sectionStyle = {
        backgroundImage: banner && "url(" + SiteUtil.localiseImgUrl(banner) + ")"
      };

    const isLoggedIn = useSelector(state => state.isLogged);
    return (
        <div className="pure-g header pure-menu-fixed" style={ sectionStyle }>
            
            <div className="pure-u-1 pure-u-md-3-4">
                <h1 className="title">{title}</h1>
            </div>
            <div className="pure-u-1 pure-u-md-1-4">
                <div className="pure-g menu">
                    {links &&
                        links.map((d, i) => 
                        <div className="pure-u-1" key={i}>
                            <Link to={d.PostName}>{d.Title}</Link>
                        </div>
                        )
                    }
                    {isLoggedIn &&
                        <div className="pure-u-1">
                            <Link to='Admin'>Admin</Link>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PageHeader