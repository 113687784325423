import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import React, { useState, useEffect } from "react";
import { PostController as API } from "./service/PostController"
import PageFooter from './pages/components/footer';
import PageHeader from "./pages/components/header";
import Home from './pages/home/Home'
import Blog from './pages/blog/Blog'
import Blogs from './pages/blog/Blogs'

import Article from './pages/article/article'
import Articles from './pages/article/articles'

import Page404 from './pages/notfound/Page404'

import Admin from './admin/Admin'

import './App.scss';


function App() {
  const pathname = window.location.pathname;

  const [statusCode, setstatusCode] = useState(200);
  const [page, setPage] = useState({
    SiteName:"",
    BannerImg:"",
    MainMenu:[]
    });
  
  const [siteMap, setsiteMap] = useState([{Route:'/', Template:'Home'}]);

  useEffect(() => {
    let pageName = pathname;

    async function fetchSiteMap() {
      API.getSiteMap(pageName).then((data) => {
        setstatusCode(data.StatusCode);
        setsiteMap(data.Routes);
        setPage({SiteName:data.Title, MainMenu: data.MainMenu, BannerImg: data.Banner});
      })
    } 
    fetchSiteMap();
  }, [pathname]); // Or [] if effect doesn't need props or state

  return (
    
    <div>

    <Router>
    <PageHeader title={page.SiteName} links={page.MainMenu} banner={page.BannerImg}/>
      <Routes>
        <Route path='/admin' exact element={<Admin/>} />
        <Route path='/admin/:id' exact element={<Admin/>} />
        {siteMap &&                
          siteMap.map((d, i) => 
          
            <Route exact path={d.Route} element={
              d.Template === 'Blog' ? <Blogs/>
             : d.Template === 'BlogPage' ? <Blog/>
             : d.Template === 'Article' ? <Articles/>
             : d.Template === 'ArticlePage' ? <Article/>
             : <Home/>
          } key={i}/>
          
          )        
        }
        <Route path='Admin' exact element={<Admin/>} />
        {statusCode === 404 &&
          <Route path='*' exact element={<Page404/>} />
        }
      </Routes>
    <PageFooter />  
    </Router>      
    </div>
  );
}

export default App;
