import { api } from "./axiosConfigs"

// if(localStorage.getItem('currentUser'))
// {
//     let x =JSON.parse(localStorage.getItem('currentUser'));
//     request = request.clone({
//         setHeaders: { 
//             Authorization: 'Bearer '+ x.access_token//localStorage.getItem('currentUser')
//         }
//     });
// }

export const APILayer = {
    post: async function(endPoint, body, header, cancel = false){
        const response = await api.post(endPoint, body, {headers: header})

        return response.data
    },
    get: async function (endPoint, cancel = false) {   
        const response = await api.get(endPoint)
        return response.data
    },
    getbyName: async function (endPoint, pageName, cancel = false) {
        const response = await api.get(endPoint +'?id='+pageName)

        return response.data
    },
    getbyId: async function (endPoint, id, cancel = false) {
        const response = await api.get(endPoint+'/'+id)

        return response.data
    },
    add: async function (endPoint, body, onError) {
        const response = await api.post(endPoint, body)
        .catch(error => {
            const statusCode = error.response ? error.response.status : null;            
            if(onError){
                onError(statusCode)
            }
        })

        return response.data
    },
    update: async function (endPoint, id, body, cancel = false) {
        const response = await api.put(endPoint+'/'+id, body)

        return response.data
    },
    delete: async function (endPoint, id, cancel = false) {
        const response = await api.delete(endPoint+'/'+id)

        return response.data
    }
}

// defining the cancel API object for ProductAPI
//const cancelApiObject = defineCancelApiObject(PokemonAPI)