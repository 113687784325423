import React from "react";


function ContentHtml({content, onEdit, onDelete}){

    function editContent(e){
        e.preventDefault();
        onEdit();
    }
    function deleteContent(e){
        e.preventDefault();
        onDelete(onDelete.PostContentId);
    }

    return (
        <div className="pure-g">
            <div className="pure-u-1-4 btn-align-right">
                <button className="pure-button img-btn" onClick={editContent} title="Edit">
                    <i className="fa fa-edit"></i>
                </button>
                <button className="pure-button img-btn" onClick={deleteContent} title="Delete">
                    <i className="fa fa-delete"></i>
                </button>
            </div>
            <div className="pure-u-3-4">
                {content.Title!=='' ? content.Title : 
                <div dangerouslySetInnerHTML={{__html: content.Content}}/> }
            </div>
        </div>
  
    )
}

export default ContentHtml